<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="false"
    >
    </EForm>
<!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
<!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <div slot="isCertification" slot-scope="scope">
        {{scope.data.isCertification==1?'是':'否'}}
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '姓名',
            prop: 'name',
          },
          {
            label: '手机',
            prop: 'mobile',
          },
          {
            label: '注册设备',
            prop: 'registDevice',
          },
          {
            label: '注册时间',
            prop: 'createTime',
          },
          {
            label: '是否实名认证',
            prop: 'isCertification',
            type:'slot'
          },
          {
            label: '身份证号码',
            prop: 'cardNo',
          },
          {
            label: '实名认证时间',
            prop: 'certificationTime',
          },

        ],
        formColumns: [
          {
            title: '姓名',
            type: 'text',
            property: 'name',
            show: true,
          },

          {
            title: '手机号',
            type: 'text',
            property: 'mobile',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'cardNo',
            show: true,
          },

          {
            title: '注册起始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true,
          },
          {
            title: '注册截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true,
          },
          {
            title: '实名认证',
            type: 'select',
            property: 'isCertification',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '是',
                value: 1,
              },
              {
                label: '否',
                value: 0,
              },
            ],
          },

        ],
        tableData: [],
        searchForm: {
          name: null,
          mobile: null,
          cardNo: null,
          createTimeStart: null,
          createTimeEnd: null,
          isCertification: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },

      async getData() {
        let res = await Http.appAccountList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
